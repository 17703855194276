import React from 'react';
import Login from './components/Login';
import { Switch, Route } from 'react-router-dom';
import TrainSchedules from './components/TrainSchedules';

function App() {
  return (
    <Switch>
      <Route path="/" exact>
        <Login />
      </Route>
        <Route path="/train-schedules" >
          <TrainSchedules />
        </Route>
    </Switch>
  );
}

export default App;
