import React, { useContext, useEffect, useRef, useState } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './Gantt.css';
import { AccountContext } from '../store/Account';
import moment from 'moment-timezone';
import getApiDataDhtmlx from '../data/create-api-data-dhtmlxGantt'

const Gantt = () => {
    const ganttContainer = useRef<any>(null);
    const ganttMarker = useRef<any>(null);
    const ganttScrollPosition = useRef<any>({ x: 0, y: 0, height: 0, width: 0, inner_height: 0, inner_width: 0 });
    const [today, setToday] = useState(new Date(moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')))

    const { API_data_dhtmlxGantt, set_API_data_dhtmlxGantt, setLoading } = useContext(AccountContext)

    useEffect(() => {
        const interval = setInterval(() => {
            getApiDataDhtmlx(set_API_data_dhtmlxGantt)
            const now = new Date(moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss'));
            now.setMinutes(now.getMinutes());
            setToday(now)
        }, 60000);
        return () => {
            clearInterval(interval)
        };
    }, []);

    const nowMarker = {
        start_date: today,
        css: "status_line",
        color: '#1aed1c'
    };

    const handleRefresh = () => {
        setLoading(true)
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                const currentScrollPosition = gantt.getScrollState();
                ganttScrollPosition.current = currentScrollPosition;
                gantt.clearAll();
                gantt.parse(API_data_dhtmlxGantt);
                const links = gantt.getLinks()
                links.forEach((link: any) => {
                    gantt.deleteLink(link.id);
                })

                gantt.batchUpdate(() => {
                    gantt.eachTask((task: any) => {
                        if (task.linkWithId) {
                            gantt.addLink({ source: task.id, target: task.linkWithId, type: gantt.config.links.finish_to_start });
                        }
                    })
                });
                gantt.deleteMarker(ganttMarker.current);
                ganttMarker.current = gantt.addMarker(nowMarker);
                gantt.showDate(today)
                gantt.scrollTo(null, ganttScrollPosition.current.y);
                setLoading(false)
                resolve();
            }, 5000)
        })
    };

    useEffect(() => {
        const todayStartDate = new Date(today.getTime()).setHours(0, 0, 0, 0);
        const tomorrowEndDate = new Date(today.getTime() + (24 * 60 * 60 * 1000)).setHours(24, 0, 0, 0);
        gantt.config.date_format = "%d-%m-%Y %H:%i";
        gantt.config.min_column_width = 100;
        gantt.config.duration_unit = "minute";
        gantt.config.duration_step = 1;
        gantt.config.scale_height = 75;
        gantt.config.row_height = 65;
        gantt.config.details_on_create = true

        gantt.config.columns = [
            { name: "text", label: "Train name", tree: true, width: "200", resize: true },
        ];

        gantt.templates.leftside_text = function (start, end, task: any) {
            if (task?.departureStation) {
                return "<span style='color:black;'>" + task?.departureStation + "</span>";
            } else {
                return '';
            }
        };

        gantt.templates.rightside_text = function (start, end, task: any) {
            if (task?.arrivialStation) {
                return "<span style='color:black;'>" + task?.arrivialStation + "</span>";
            } else {
                return ''
            }
        };

        gantt.plugins({
            marker: true
        });

        gantt.templates.task_text = function (start, end, task) {
            return "<div style='width:100%; display: flex'><div style='display: flex; flex-direction:column; padding-left:10px; justify-content: left;'><span class='scheduled-time' style='color:" + task.textColor + "; margin-top: -15px; text-align: left;'>S: " + task.scheduledDepartureTime + "</span><span class='scheduled-time' style='color:" + task.textColor + "; margin-top: -32px; text-align: left;'>A: " + task.projectedDepartureTime + "</span></div><div style='display: flex; flex-direction:column; flex: auto;'><span style='color:" + task.textColor + "; flex: auto; margin-top: -15px;'>" + task?.bookedPassengers + "</span><span style='color:" + task?.textColor + "; flex: auto; margin-top: -40px'><strong>" + task.text + "</strong></span></div><div style='display: flex; flex-direction:column; padding-right: 10px; justify-content: right;'><span class='scheduled-time' style='color:" + task.textColor + "; margin-top: -15px; text-align: right;'>S: " + task.scheduledArrivialTime + "</span><span class='scheduled-time' style='color:" + task.textColor + "; margin-top: -32px; text-align: right;'>A: " + task.projectedArrivialTime + "</span></div></div>";
        };

        gantt.config.start_date = new Date(todayStartDate)
        gantt.config.end_date = new Date(tomorrowEndDate)

        gantt.config.scales = [
            { unit: "hour", step: 1, format: "%F %j '%y, %l, %g %a" },
            { unit: "minute", step: 5, format: "%i" }
        ];
        gantt.config.readonly = true;
        gantt.config.branch_loading = true;
        gantt.init(ganttContainer.current);
        ganttMarker.current = gantt.addMarker(nowMarker);

        gantt.showDate(today)

        return () => {
            const currentScrollPosition = gantt.getScrollState();
            ganttScrollPosition.current = currentScrollPosition;
            gantt.deleteMarker(ganttMarker.current);
        }
    }, [])

    useEffect(() => {
        handleRefresh()
    }, [API_data_dhtmlxGantt])

    return (
        <>
            <button className="button" onClick={handleRefresh} type="button">
                <span className="button__text">Refresh</span>
                <span className="button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48" viewBox="0 0 48 48" height="48" className="svg"><path d="M35.3 12.7c-2.89-2.9-6.88-4.7-11.3-4.7-8.84 0-15.98 7.16-15.98 16s7.14 16 15.98 16c7.45 0 13.69-5.1 15.46-12h-4.16c-1.65 4.66-6.07 8-11.3 8-6.63 0-12-5.37-12-12s5.37-12 12-12c3.31 0 6.28 1.38 8.45 3.55l-6.45 6.45h14v-14l-4.7 4.7z"></path><path fill="none" d="M0 0h48v48h-48z"></path></svg></span>
            </button>
            <div
                className='gantt-container'
                ref={ganttContainer}
                style={{ width: '100%', height: '95%', color: 'red' }}
            >
            </div>
        </>
    );
};

export default Gantt;
