import React, { createContext, useEffect, useState } from 'react'
import Pool from '../../UserPool'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import AWS from 'aws-sdk';
import axios from 'axios';
import moment from 'moment-timezone';
import { getS3BookedPassengers, getToken } from '../get-booked-passengers-from-sqills/getBookedPassengers';
import getApiDataDhtmlx from '../data/create-api-data-dhtmlxGantt';

let contextValue: any;

const AccountContext = createContext(contextValue);

const Account = (props: any) => {
    const [user, setUser] = useState<CognitoUser>();
    const [session, setSession] = useState()
    const [loading, setLoading] = useState(false)
    const [API_data_dhtmlxGantt, set_API_data_dhtmlxGantt] = useState({ data: [] });
    let ganttChartData: any[] = []

    useEffect(() => {
        getSession()
        getToken()
        getApiDataDhtmlx(set_API_data_dhtmlxGantt)
        const intervalId = setInterval(getToken, 300000);

        return () => clearInterval(intervalId);
    }, [])

    const signup = async (Username: string, Password: string) => {
        setLoading(true)
        return await new Promise((resolve, reject) => {
            Pool.signUp(Username, Password, [], [], (err, data) => {
                if (err) {
                    console.log(err)
                    setLoading(false)
                    reject(err)
                }
                if (data) {
                    console.log(data);
                    setLoading(false)
                    resolve(data)
                }
            })
        })
    }

    const authenticate = async (Username: string, Password: string) => {
        setLoading(true)
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool })

            const authDetails = new AuthenticationDetails({ Username, Password })

            user.authenticateUser(authDetails, {
                onSuccess: async (data) => {
                    console.log("onSuccess: ", data);
                    await getSession()
                    resolve(data)
                },
                onFailure: (err) => {
                    console.error("onFailure: ", err.message)
                    setLoading(false)
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    resolve(data)
                    setLoading(false)
                    console.log(data);
                }
            })
        })
    }

    const newPasswordRequired = async (Username: string, userAttributes: any, newPass: string) => {
        setLoading(true)
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool })

            // const authDetails = new AuthenticationDetails({ Username, Password })

            user.completeNewPasswordChallenge(newPass, userAttributes, {
                onSuccess: (data) => {
                    setLoading(false)
                    resolve(data)
                },
                newPasswordRequired: () => {
                    if (userAttributes?.email_verified) {
                        delete userAttributes.email_verified
                    }
                    if (userAttributes?.phone_number_verified) {
                        delete userAttributes.phone_number_verified;
                    }

                    user.completeNewPasswordChallenge(newPass, userAttributes, {
                        onSuccess: (data) => {
                            console.log("onSuccess: ", data);
                            setLoading(false)
                            resolve(data)
                        },
                        onFailure: (err) => {
                            console.error("onFailure: ", err.message)
                            setLoading(false)
                            reject(err)
                        },
                        newPasswordRequired: (data) => {
                            resolve(data)
                            setLoading(false)
                            console.log(data);
                        }
                    })
                },
                onFailure: err => {
                    console.log('onFailure: err', err);
                    setLoading(false)
                    reject(err)
                },
            })
        })
    }



    const getSession = async () => {
        setLoading(true)
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser()
            if (user) {
                setUser(user)
                user.getSession(async (err: any, session: any) => {
                    if (err) {
                        reject(err)
                    } else {
                        const attributes: any = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err: any, attributes: any) => {
                                if (err) {
                                    setLoading(false)
                                    reject()
                                } else {
                                    const results: any = {};
                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }
                                    resolve(results)
                                }
                            })
                        })
                        setSession({ user, ...session, ...attributes })
                        setLoading(false)
                        resolve({ user, ...session, ...attributes })
                    }
                })
                setLoading(false)
            } else {
                setLoading(false)
                return;
            }
        })
    }

    const logout = () => {
        setLoading(true)
        if (user instanceof CognitoUser) {
            user.signOut()
            setUser(undefined)
            setSession(undefined)
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <AccountContext.Provider value={{
            authenticate, getSession, logout,
            newPasswordRequired, signup, user, session, loading,
            setLoading, ganttChartData, API_data_dhtmlxGantt, set_API_data_dhtmlxGantt,
        }}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext }