import React, { useContext } from 'react'
import { AccountContext } from './store/Account'
import Loading from './Loading'
import Gantt from './Gantt';
import { Link, useHistory } from 'react-router-dom';
import bllogo from '../images/brightline-logo.webp'

const TrainSchedules = () => {

    const { logout, user, session, loading } = useContext(AccountContext)


    if (!user || !session) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: "column" }}>
                <h1 style={{ margin: '10px auto' }}>You are not authenticated. Log in first!</h1>
                <Link to='/'>
                    <button className='logout-train-schedules' style={{ width: '40%', margin: '20px 30%' }}>Sign In Here</button>
                </Link>
            </div>
        )
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '5vh', marginBottom: '20px' }}>
                <div style={{ textAlign: 'left' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img src={bllogo} alt='Brightline Logo' style={{ height: '150%', width: '200px', objectFit: 'cover' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <p style={{ marginTop: '-37px' }}>S: Scheduled Time</p>
                            <p style={{ marginTop: '-10px' }}>A: Actual Time</p>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', margin: 'auto', paddingTop: '10px' }}>
                    <strong style={{ borderBottom: '5px solid #ffdb00' }}>Train Schedules</strong>
                    {loading && <div style={{marginTop: '10px'}}><Loading /></div>}
                </div>
                <div style={{ textAlign: 'right' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p style={{ paddingRight: '10px' }}>User: {session.email}</p>
                        <Link to='/'>
                            <button className='logout-train-schedules' onClick={logout}>Log out</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="gantt-container">
                <Gantt />
            </div>
        </div>
    )
}

export default TrainSchedules