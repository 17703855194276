import React, { useState, useContext } from 'react'
import { AccountContext } from './store/Account'
import './styles/Login.css'
import { Link, useHistory } from 'react-router-dom';
import Loading from './Loading';
import { Amplify } from 'aws-amplify';
import { awsExports } from '../aws-export'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import LoginComponent from './LoginComponent';
import bllogo from '../images/brightline-logo.webp'
import Gantt from './Gantt';

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_CLIENT_ID,
  }
})

const Login = () => {
  return (
    <Authenticator>
      {({ signOut, user }) => <LoginComponent user={user} signOut={signOut} />}
    </Authenticator>
  )
}

export default Login