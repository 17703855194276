import axios from "axios";

const requestBody = {
    "client_id": "doc4si61k41740cg9plq11e344y4w33d4e8ab39yg3u8nfv9c6",
    "grant_type": "https://com.sqills.s3.oauth.agent",
    "username": "brightline.agent",
    "password": "Vb{'rMCAQ1!(&h{27[@5;1",
    'client_secret': 'w66me8u77c886aeh5wda56kad89aey13hth0uw8p3w4uzf45l1',
    "code": "INTERNET"
}

async function getToken() {
    try {
        localStorage.removeItem('access_token')
        const response: any = await axios.post(`https://api.brightline-prod.cloud.sqills.com/oauth/v2/token`, requestBody)
        localStorage.setItem("access_token", response.data.access_token)
        return response?.data;
    } catch (err: any) {
        console.log('Error while getting token for s3:', err.message)
    }
}

async function getS3BookedPassengers(serviceId: string, date: string) {
    try {
        let access_token = localStorage.getItem("access_token");
        let headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`
        };
        const { data } = await axios.get(`https://api.brightline-prod.cloud.sqills.com/api/v3/services/${serviceId}/${date}/passenger-counts`, { headers });
        return data;
    } catch (error) {
        return 'Error'
    }
}

export { getToken, getS3BookedPassengers }