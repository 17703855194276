import axios from 'axios';
import moment from 'moment-timezone';
import { getS3BookedPassengers } from '../get-booked-passengers-from-sqills/getBookedPassengers';

const staionsInOrderArray = ['MCO', 'WPT', 'RRN', 'FBT', 'AVE', 'EKW']
const staionsBackwards = ['EKW', 'AVE', 'FBT', 'RRN', 'WPT', 'MCO']

function getParentIndex(value: any, ganttChartData: any[]) {
    return ganttChartData.find((element: any) => {
        if (element.text === `bl ${value}` && element.type === 'project') {
            return element.id
        }
    })
}

function getNewAcronymsStation(name: string) {
    switch (name) {
        case "EKW": return "MIA";
        case "AVE": return "AVE";
        case "FBT": return "FTL";
        case "RRN": return "BOC";
        case "WPT": return "WPB";
        case "MCO": return "ORL"
    }
}

function gets3bookingData(array: any, depStation: any, arrStation: any) {
    if (array && depStation && arrStation) {
        let bookedPassengers: number = 0;
        let depStationIndex = staionsInOrderArray.findIndex(i => i === depStation)
        let arrStationIndex = staionsInOrderArray.findIndex(i => i === arrStation)
        if (arrStationIndex > depStationIndex) {
            for (let i = 0; i <= depStationIndex; i++) {
                let depStations: [] = array.filter((station: any) => station.origin_station_code === staionsInOrderArray[i])

                // eslint-disable-next-line no-loop-func
                depStations.forEach((station: any) => {
                    bookedPassengers += station.count.count
                })
            }
            if (depStationIndex > 0) {
                for (let i = 1; i <= depStationIndex; i++) {
                    let stationsRemoved: [] = array.filter((station: any) => station.destination_station_code === staionsInOrderArray[i])

                    // eslint-disable-next-line no-loop-func
                    stationsRemoved.forEach((station: any) => {
                        bookedPassengers -= station.count.count
                    })
                }
            }
        } else {
            const depStationIndex = staionsBackwards.findIndex(i => i === depStation)
            // const arrStationIndex = staionsBackwards.findIndex(i => i === arrStation)
            for (let i = 0; i <= depStationIndex; i++) {
                let depStations: [] = array.filter((station: any) => station.origin_station_code === staionsBackwards[i])

                // eslint-disable-next-line no-loop-func
                depStations.forEach((station: any) => {
                    bookedPassengers += station.count.count
                })
            }
            if (depStationIndex > 0) {
                for (let i = 1; i <= depStationIndex; i++) {
                    let stationsRemoved: [] = array.filter((station: any) => station.destination_station_code === staionsBackwards[i])

                    // eslint-disable-next-line no-loop-func
                    stationsRemoved.forEach((station: any) => {
                        bookedPassengers -= station.count.count
                    })
                }
            }
        }
        return bookedPassengers;
    } else {
        return null;
    }
}

function getDetailedTrip(id: string) {
    return axios.get(`https://brightline.hafas.cloud/api/journeyDetail?accessId=29ef8c98-bb68-473a-a31d-6aaa7ac4d9e7&format=json&id=${id}`)
        .then(res => res.data)
        .catch(err => err.message)
}

function getThisDateTrip(date: string) {
    return axios.get(`https://brightline.hafas.cloud/api/trainSearch?accessId=29ef8c98-bb68-473a-a31d-6aaa7ac4d9e7&format=json&match=*&date=${date}`)
        .then(res => res.data)
        .catch(err => err.message)
}

async function getApiData() {
    try {
        const getFormattedDate = (offset: number = 0) => {
            const date = new Date(moment().tz('America/New_York').add(offset, 'days').format('YYYY-MM-DD HH:mm:ss'));
            return date.toLocaleDateString("en-CA");
        };

        const fetchDataForDate = async (date: string) => {
            const data = await getThisDateTrip(date);
            const detailsPromises = data.JourneyDetail.map(async (journey: any) => {
                const id = encodeURIComponent(journey.ref);
                const details = await getDetailedTrip(id);
                return {
                    ...journey,
                    moreDetails: details
                };
            });
            return Promise.all(detailsPromises);
        };

        const [todayDataDetails, tomorrowDataDetails] = await Promise.all([
            fetchDataForDate(getFormattedDate()),
            fetchDataForDate(getFormattedDate(1))
        ]);

        const resolvedAllDataDetails = [...todayDataDetails, ...tomorrowDataDetails];
        resolvedAllDataDetails.sort((journeyOne, journeyTwo) =>
            (journeyOne.moreDetails?.Product[0]?.num || 0) - (journeyTwo.moreDetails?.Product[0]?.num || 0)
        );

        return resolvedAllDataDetails;
    } catch (error: any) {
        console.log('error', error.message)
    }
}

async function getS3Data(journey: any) {
    //getS3BookedPassengers for all children at once
    const serviceId = journey?.Names?.Name[0]?.Product?.matchId
    const date = moment(journey?.Stops?.Stop[0]?.depDate).format('YYYY-MM-DD')
    return getS3BookedPassengers(serviceId, date).then(data => {
        return data;
    })  
    .catch(error => {
        return 'Error';
    });
}

function extractProjectData(ganttChartData: any[], journey: any, idx: number) {
    const project_index: number = ganttChartData.length > 1 ? ganttChartData[ganttChartData.length - 1].id + 1 : ganttChartData.length + 1
    const projectName = `bl ${journey?.moreDetails?.Product[0]?.num}`;
    const project_start_date = moment(journey?.Stops?.Stop[0]?.depDate).format('DD-MM-YYYY');

    const projectExist = ganttChartData.find((project: any) => project.text === projectName && project.type === 'project')

    if (projectExist) return null;

    return {
        id: project_index,
        text: projectName,
        type: 'project',
        start_date: project_start_date,
        render: 'split',
        hasChild: true
    };
}

async function extractChildData(ganttChartData: any[], journey: any) {
    const s3Data: any = await getS3Data(journey)

    const haveRequiredData = journey?.moreDetails?.Product[0]?.num
    if (!haveRequiredData) return null;

    const serviceId = journey?.Names?.Name[0]?.Product?.matchId || ''
    const childName = "BL " + serviceId + "/BLF " + (journey?.moreDetails?.Notes?.Note[1]?.value)
    const stopArray = (journey?.moreDetails?.Stops?.Stop ?? [])
    // console.log('stopArray',stopArray);
    stopArray.forEach((stopDetail: any, index: number) => {
        if (!stopDetail?.depDate) return null
        // console.log('stopDetail',stopDetail,'index',index)

        let haveRequiredDataForChild = haveRequiredData && stopDetail?.depDate && stopDetail?.depTime && stopArray[index + 1]?.arrDate && stopArray[index + 1]?.arrTime && serviceId
        //colors when no info
        let color: string = '#f50101';
        let textColor: string = '#ffff';
        let bookedPassengers: string = '-'

        if (!(stopDetail?.depDate && stopDetail?.depTime && stopArray[index + 1]?.arrDate && stopArray[index + 1]?.arrTime)) return null

        const id: number = ganttChartData[ganttChartData.length - 1].id + 1;
        const childGanttStartDate = (stopDetail?.rtDepDate && stopDetail?.rtDepTime) ? (moment(stopDetail.rtDepDate).format('DD-MM-YYYY') + " " + stopDetail.rtDepTime.slice(0, -3)) : (moment(stopDetail.depDate).format('DD-MM-YYYY') + " " + stopDetail.depTime.slice(0, -3))
        const childGanttEndDate = (stopArray[index + 1]?.rtArrDate && stopArray[index + 1].rtArrTime) ? (moment(stopArray[index + 1].rtArrDate).format('DD-MM-YYYY') + " " + stopArray[index + 1].rtArrTime.slice(0, -3)) : (moment(stopArray[index + 1].arrDate).format('DD-MM-YYYY') + " " + stopArray[index + 1].arrTime.slice(0, -3))
        //cancelled Trip
        const cancelledTrip = (stopDetail.Notes?.Note[1]?.value === 'Stop cancelled') ? true : false
        //moment(stopDetail.depDate).format('DD-MM') + "/" +
        const scheduledDepartureTime = stopDetail.depTime.slice(0, -3)
        // moment(stopArray[index + 1].arrDate).format('DD-MM') + "/" +
        const scheduledArrivialTime = stopArray[index + 1].arrTime.slice(0, -3)
        const parentObject = getParentIndex(journey?.moreDetails?.Product[0]?.num, ganttChartData)
        const parent = parentObject.id
        //const parent = ganttChartData.length - index;

        //projected dep/arr time
        //moment(stopDetail?.rtDepDate).format('DD-MM') + "/" +
        const projectedDepartureTime = (stopDetail?.rtDepDate && stopDetail?.rtDepTime) ? (stopDetail?.rtDepTime.slice(0, -3)) : scheduledDepartureTime
        //moment(stopArray[index + 1]?.rtArrDate).format('DD-MM') + "/" +
        const projectedArrivialTime = (stopArray[index + 1]?.rtArrDate && stopArray[index + 1].rtArrTime) ? stopArray[index + 1].rtArrTime.slice(0, -3) : scheduledArrivialTime

        const timeNowInNewYork = new Date(moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')).getTime();
        const departureComparisontime = (stopDetail?.rtDepDate && stopDetail?.rtDepTime) ? new Date(stopDetail?.rtDepDate + " " + stopDetail?.rtDepTime).getTime() : new Date(stopDetail?.depDate + " " + stopDetail?.depTime).getTime();
        // const arrivialComparisonTime = (stopArray[index + 1]?.rtArrDate && stopArray[index + 1].rtArrTime) ? new Date(stopArray[index + 1]?.rtArrDate + " " + stopArray[index + 1]?.rtArrTime).getTime() : new Date(stopArray[index + 1]?.arrDate + " " + stopArray[index + 1]?.arrTime).getTime()
        if (departureComparisontime > timeNowInNewYork) {
            //colors if future train
            color = haveRequiredDataForChild ? "#fffd32" : '#f50101';
            textColor = haveRequiredDataForChild ? "#1a1a1a" : '#ffff';
        } else {
            //colors for now or past trains
            color = "green";
            textColor = '#ffff';
        }

        //filter s3bookedPassengers for this segment 
        const departureStation = stopDetail?.altId[0] ?? "/"
        const arrivialStation = stopArray[index + 1]?.altId[0] ?? "/"
        let trains3Data = 0
        if(s3Data && s3Data !== 'Error' && s3Data?.od_passenger_counts) trains3Data = gets3bookingData(s3Data.od_passenger_counts, departureStation, arrivialStation) || 0

        haveRequiredDataForChild = haveRequiredDataForChild && stopDetail?.altId[0] && stopArray[index + 1]?.altId[0] && trains3Data;
        ganttChartData.push({
            id,
            text: childName,
            start_date: childGanttStartDate,
            end_date: childGanttEndDate,
            parent,
            color: (haveRequiredDataForChild && !cancelledTrip) ? color : '#f50101',
            textColor: haveRequiredDataForChild ? textColor : '#ffff',
            scheduledDepartureTime,
            scheduledArrivialTime,
            ...(index === 0 ? { departureStation: getNewAcronymsStation(departureStation) } : ''),
            ...(scheduledDepartureTime !== projectedDepartureTime ? { linkWithId: id - 1 } : ''),
            ...(cancelledTrip ? { cancelledTrip: true } : ''),
            arrivialStation: getNewAcronymsStation(arrivialStation),
            bookedPassengers: trains3Data ? trains3Data : bookedPassengers,
            projectedDepartureTime,
            projectedArrivialTime
        })
    })
}

async function getApiDataDhtmlx(setGanttChartData: any) {
    let ganttChartData: any[] = []
    const trainSchedulesData = await getApiData()

    if (!trainSchedulesData) return;

    trainSchedulesData.forEach(async (journey: any, idx: number) => {

        const projectData = extractProjectData(ganttChartData, journey, idx);
        if (projectData) ganttChartData.push(projectData);

        await extractChildData(ganttChartData, journey);
    })

    setGanttChartData({ data: ganttChartData })
    return { data: ganttChartData }
}

export default getApiDataDhtmlx;