import React, { useContext, useEffect } from 'react'
import './styles/Login.css'
import { Link } from 'react-router-dom';
import { AccountContext } from './store/Account';

interface LoginComponentProps {
    user: any
    signOut: any
}

const LoginComponent = ({ user, signOut }: LoginComponentProps) => {
    const { getSession } = useContext(AccountContext)

    useEffect(() => {
        getSession()
    }, []);

    return (
        <div className="login-card">
            <div className='card-header label'>
                <p>Welcome back!</p>
            </div>
            <div className="form-group">
                <Link to='/train-schedules'>
                    <button
                        className='logout-train-schedules'
                    >
                        Train Schedules Portal
                    </button>
                </Link>
            </div>
            <div className="form-group">
                <button onClick={signOut} type="submit">Sign Out</button>
            </div>
        </div>
    )
}

export default LoginComponent